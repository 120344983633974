/*eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'

import verifyForm from '@/components/validation-document'

Vue.use(VueRouter)

const routes = [
  {
    path: '/check/:hash',
    name: 'verifyForm',
    component: verifyForm,
  },
  {
    path: '/',
    name: 'main',
    component: verifyForm,
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
