<template>
        <router-view></router-view>
</template>

<script>
    /* eslint-disable */
    import Vue from "vue";
    import VueRouter from 'vue-router';
    import accounting from "accounting";

    import Axios from "axios";
    import {mapActions, mapGetters} from "vuex";

    Vue.use(VueRouter);

    export default {
    name: "app",
    components: {},
    watch: {},
  };
</script>


