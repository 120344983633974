<template>
  <div v-if="isLoaded" class="container">
    <div class="header">
      <div class="title-block">
        <h3 class="title">NILL STOCK - DOCUMENT VALIDATION</h3>
        <div class="line"></div>
      </div>
    </div>
    <div class="main">
      <div class="main-block" :class="{'main-block-not-verified': !isVerified}">
        <template v-if="!isVerified">
          <div>
            <div class="text-block">
              <span class="text-bold text-title">Document ID:</span>
              <span class="not-valid-color">&nbsp;&nbsp; NOT VALID</span>
            </div>
            <div class="text-block">
              <span class="text-bold text-title">Date:</span>
              <span class="not-valid-color">&nbsp;&nbsp; NOT VALID</span>
            </div>
            <div class="text-block">
              <span class="text-bold text-title">Verification Lookup Count:</span>
              <span>&nbsp;&nbsp; 0</span>
            </div>
          </div>
        </template>
        <template v-else>
          <div>
            <div class="text-block">
              <span class="text-bold text-title">Document ID:</span>
              <span>&nbsp;&nbsp; {{ doc.number }}</span>
            </div>
            <div class="text-block">
              <span class="text-bold text-title">Date:</span>
              <span>&nbsp;&nbsp; {{ doc.date }}</span>
            </div>
            <div class="text-block">
              <span class="text-bold text-title">Verification Lookup Count:</span>
              <span>&nbsp;&nbsp; {{ doc.count }}</span>
            </div>
          </div>
        </template>
        <div v-if="isVerified && doc.logo" class="logo">
          <!--          <span class="text-logo">LOGO</span>-->
          <img :src="doc.logo" alt="">
        </div>
      </div>
    </div>
    <div v-if="isVerified" class="footer">
      <div class="verified-block">
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="4 3 16 16"
                 style="fill: #29BBC1;transform: ;msFilter:;"><path
                d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path></svg>
        </span>
        <span>VERIFIED</span>
      </div>
      <div class="footer-text">Powered by - partssearch</div>
    </div>
    <div v-else class="footer">
      <div class="not-verified-block" style="height: 130px; min-height: 130px;     padding-top: 30px;">
        <span style="text-align: center;" class="not-verified-text not-valid-color">DOCUMENT<br/>NOT VERIFIED</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Axios from 'axios'

export default {
  name: 'validation-document',
  data () {
    return {
      isVerified: false,
      isLoaded: false,
      doc: {
        number: '',
        date: '',
        logo: '',
        count: '',
      },
      hash: '',
    }
  },
  computed: {},
  methods: {
    checkQR (hash) {
      Axios.post(appConfig.baseAPIURL + '/qr/check/' + hash)
          .then((response) => {
            if (response.data._status && response.data?._verify === true) {
              this.doc = response.data.doc
              this.isVerified = true
              this.isLoaded = true
            } else {
              this.isVerified = false
              this.isLoaded = true
            }
          })
          .catch((error) => {
          })
    }
  },
  mounted () {
    if (this.$route?.params?.hash) {
      this.hash = this.$route?.params?.hash
      this.checkQR(this.hash)
    } else {
      this.isLoaded = true
      this.isVerified = false
      // this.showErrorPage();
    }
  },
}
</script>

<style scoped>

</style>
