/* eslint-disable */

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Axios from "axios";
import {appConfig} from "./config.js";
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";
import './styles/style.css';
// import "vue-themify-icons/themify-icons/themify-icons.css";

// import BootstrapVue from "bootstrap-vue";
import VueMobileDetection from "vue-mobile-detection";
// import moment from 'moment';
import VueToast from 'vue-toast-notification';

Vue.use(appConfig);
window.appConfig = appConfig;
//
// Vue.use(BootstrapVue);
Vue.use(VueMobileDetection);

Vue.config.productionTip = false

Axios.defaults.baseURL = appConfig.baseAPIURL;

Vue.prototype.$http = Axios;

Vue.filter("formatDatetime", function(val) {
  return moment(val).format('DD/MM/YYYY - hh:mma');
})

Vue.directive("scroll", {
  inserted: function(el, binding) {
    let f = function(evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f);
      }
    };
    window.addEventListener("scroll", f);
  },
});

Vue.directive('scroll-into-view', {
  inserted: function (el) {
    el.scrollIntoView({block: "center", inline: "center"});
  }
});

Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
})

// window.NProgress = require('nprogress');

//import 'vue-toast-notification/dist/theme-default.css';
Vue.use(VueToast,{
  duration: 6000,
  position: 'top-right'
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
